import { useTheme } from '@emotion/react';
import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { tokens } from '../../theme';

const DieselHourlyAreaChart = () => {
    const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const data = [
    { date: '2023-09-26 00:00:00', quantity: 100 },
    { date: '2023-09-26 01:15:00', quantity: 80 },
    { date: '2023-09-26 02:30:00', quantity: 120 },
    { date: '2023-09-26 03:45:00', quantity: 90 },
    { date: '2023-09-26 04:10:00', quantity: 70 },
    { date: '2023-09-26 05:25:00', quantity: 110 },
    { date: '2023-09-26 06:40:00', quantity: 95 },
    { date: '2023-09-26 07:55:00', quantity: 130 },
    { date: '2023-09-26 08:10:00', quantity: 85 },
    { date: '2023-09-26 09:25:00', quantity: 105 },
    { date: '2023-09-26 10:40:00', quantity: 75 },
    { date: '2023-09-26 11:55:00', quantity: 115 },
    { date: '2023-09-26 12:10:00', quantity: 100 },
    { date: '2023-09-26 13:25:00', quantity: 90 },
    { date: '2023-09-26 14:40:00', quantity: 120 },
    { date: '2023-09-26 15:55:00', quantity: 80 },
    { date: '2023-09-26 16:10:00', quantity: 110 },
    { date: '2023-09-26 17:25:00', quantity: 95 },
    { date: '2023-09-26 18:40:00', quantity: 130 },
    { date: '2023-09-26 19:55:00', quantity: 70 },
    { date: '2023-09-26 20:10:00', quantity: 105 },
    { date: '2023-09-26 21:25:00', quantity: 85 },
    { date: '2023-09-26 22:40:00', quantity: 115 },
    { date: '2023-09-26 23:55:00', quantity: 100 },
  ];

  // Data preprocessing to group by hours and aggregate quantities
  const aggregatedData = {};

  data.forEach((item) => {
    const date = new Date(item.date);
    const hour = date.getHours();
    const amount = item.quantity;

    if (!aggregatedData[hour]) {
      aggregatedData[hour] = { hour, totalQty: 0 };
    }

    aggregatedData[hour].totalQty += amount;
  });

  const transformedData = Object.values(aggregatedData);

  return (
    <div className={`${colors.card2['bg']} shadow-md p-4 rounded-lg`}>
      <h2 className="text-xl font-semibold mb-4">Deliveries - (Today)</h2>
      <ResponsiveContainer width="100%" height={170}>
        <AreaChart data={transformedData}>
          {/* <CartesianGrid strokeDasharray="none" /> */}
          <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
    </linearGradient>
          </defs>
          <XAxis dataKey="hour" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="totalQty" stroke="#0078D4" fill="#0078D4" fillOpacity={0.4} name="Total Quantity" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DieselHourlyAreaChart;
