import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import { CustomToast } from "../customtoast/CustomToast";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import api from "../../axios";

export default function AddContractModal({  updateTable }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setFormData({
      gstin: "",
      company:'',
      trade_name: "",
      additional_trade_name: "",
      password: "",
      city: '',
      role: "company",
    });
    setSelectedDate(null);
    setOpen(false);
  };

  const [formData, setFormData] = React.useState({
    gstin: "",
    company:'',
    trade_name: "",
    password: "",
    city: '',
    role: "company",
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedStatus, setSelectedStatus] = React.useState("");

  const [selectedDate, setSelectedDate] = React.useState(null);
  const [companyData, setCompanyData] = React.useState([])

  const [error, setError] = React.useState(null);

  const handleDateChange = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setFormData((prevData) => ({
      ...prevData,
      effective_date_of_registration: formattedDate,
    }));
    setSelectedDate(date);
  };

  const handleChange = (event) => {
    // console.log(formData);
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    console.log('sadaassaeeeeeeeeeeeeeeeee');
      api
        .post("sub-contract", formData)
        .then((response) => {
          console.log(response.data);
          updateTable();
          window.location.reload()
          // handleClose();
        })
        .catch((error) => {
          const errorMessages = error.response.data.message;
          const keys = Object.keys(errorMessages);
          const values = Object.values(errorMessages);

          toast.error(<CustomToast keys={keys} values={values} />);
        });
  };


  const getCompany = () => {
    api.get('company').then((response)=>{
      setCompanyData(response.data)
    })
  }

  React.useEffect(() => {
    getCompany()
  }, [])
  

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxHeight: "80vh",
    bgcolor: colors.card2["bg"],
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };

  return (
    <div>
      <Button
        sx={{
          backgroundColor: "rgba(62, 67, 150, 1.0)",
          color: "white",
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          borderRadius: 10,
          "&:hover": {
            backgroundColor: colors.blueAccent[800],
          },

          "@media (max-width: 600px)": {
            fontSize: "8px",
            padding: "8px 16px",
          },
        }}
        onClick={handleOpen}
      >
        <AddCircleOutlineIcon />
        &nbsp;Add Contract
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h3" align="center">
            Company Details
          </Typography>
          <Box
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography
              variant="body1"
              sx={{ marginLeft: 1, cursor: "pointer" }}
              onClick={handleClose}
            >
              Close
            </Typography>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>

          <Box
            sx={{ display: "flex", flexWrap: "wrap", marginBottom: 5, mt: 3 }}
          >
            

            
              <Box
                sx={{
                  width: "100%",
                  mt: 5,
                  [theme.breakpoints.up("sm")]: {
                    width: "50%",
                    paddingLeft: 8,
                    mt: 0,
                  },
                }}
              >
                {/* <Typography variant="subtitle1">Trade Information</Typography> */}

                <div className="relative">
            <select
            name="company"
              value={formData.company}
              onChange={handleChange}
              className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-indigo-500 focus:shadow-outline"
            >
              <option value="">Select a Company</option>

              {companyData.map((obj) => (
                <option value={obj.id}>{obj.username}</option>
              ))}

              {/* Add more options as needed */}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              {/* Custom location icon */}
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M10 0C4.477 0 0 4.477 0 10c0 3.42 1.998 6.323 4.876 7.691.213.078.457-.018.534-.231.075-.21-.019-.455-.231-.534C2.916 15.444 2 12.862 2 10 2 5.364 5.364 2 10 2s8 3.364 8 8c0 2.862-.916 5.444-2.179 7.926-.212.52.251 1.047.788.874C18.546 16.323 20 13.42 20 10 20 4.477 15.523 0 10 0zm0 18c-3.314 0-6-2.686-6-6 0-3.314 2.686-6 6-6 3.314 0 6 2.686 6 6 0 3.314-2.686 6-6 6z"></path>
              </svg>
            </div>
          </div>

                <FormControl fullWidth margin="normal">
                <TextField
                  name='city'
                  value={formData.city}
                
                  label="City"
                  // InputLabelProps={{
                  //   style: { color: 'white' } // Apply custom CSS class to the label
                  // }}
                  variant="filled"
                  onChange={handleChange}
                />
              </FormControl>

                <FormControl fullWidth margin="normal">
                  <TextField
                    name="gstin"
                    value={formData.gstin}
                    label="GSTIN/UIN"
                    // InputLabelProps={{
                    //   style: { color: 'white' } // Apply custom CSS class to the label
                    // }}
                    variant="filled"
                    onChange={handleChange}
                  />
                </FormControl>

                {/* <FormControl fullWidth margin="normal">
                <InputLabel id="gstin-uin-status-label" style={{
                  color: 'white',
                }}
                >GSTIN/UIN Status</InputLabel>

                <Select
                  name='gstin_uin_status'
                  value={formData.gstin_uin_status}
                  labelId="gstin-uin-status-label"
                  onChange={handleChange}
                  variant="filled"
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="non-active">Non-active</MenuItem>
                  <MenuItem value="unknown">Unknown</MenuItem>
                </Select>
              </FormControl> */}

                {/* <FormControl fullWidth margin="normal">
                <TextField
                  name='legal_name'
                  value={formData.legal_name}
                  label="Legal Name"
                  InputLabelProps={{
                    style: { color: 'white' } // Apply custom CSS class to the label
                  }}
                  variant="filled"
                  onChange={handleChange}
                />

              </FormControl> */}

                <FormControl fullWidth margin="normal">
                  <TextField
                    name="trade_name"
                    value={formData.trade_name}
                    label="Trade name"
                    // InputLabelProps={{
                    //   style: { color: 'white' } // Apply custom CSS class to the label
                    // }}
                    variant="filled"
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TextField
                    name="administrative_office"
                    value={formData.administrative_office}
                    label="Administrative Office"
                    // InputLabelProps={{
                    //   style: { color: 'white' }
                    // }}
                    multiline
                    rows={4}
                    variant="filled"
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    name="other_office"
                    value={formData.other_office}
                    label="Other Office"
                    // InputLabelProps={{
                    //   style: { color: 'white' }
                    // }}
                    rows={4}
                    variant="filled"
                    multiline
                    onChange={handleChange}
                  />
                </FormControl>

              </Box>
        
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: 5 }}>
            {/* <Box sx={{
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                width: '50%',
                paddingRight: 8,
              },
            }} >
              <Typography variant="subtitle1">Additional Informations</Typography>
              <FormControl fullWidth margin="normal">
            
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="MM/dd/yyyy"
                  customInput={<TextField variant='filled' label='Effective Date of Registration' sx={{ width: '100%' }} />}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="gstin-uin-status-label" style={{
                  color: 'white',
                }}
                >Constitution Of Bussiness</InputLabel>

                <Select
                  name='constitution_of_bussiness'
                  value={formData.constitution_of_bussiness}
                  labelId="gstin-uin-status-label"
                  id="gstin-uin-status"
                  // value={selectedStatus}
                  onChange={handleChange}
                  variant="filled"
                >
                  <MenuItem value="private_limited">Private Limited</MenuItem>
                  <MenuItem value="public_company">Public Company</MenuItem>
                  <MenuItem value="sole_proprietorship">Sole Proprietorship</MenuItem>
                  <MenuItem value="one_person_company">One Person Company</MenuItem>
                  <MenuItem value="partnership">Partnership</MenuItem>
                  <MenuItem value="llp">Limited Liability Partnership (LLP)</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="gstin-uin-status-label" style={{
                  color: 'white',
                }}
                >Adhaar Authentication Status</InputLabel>

                <Select
                  name='adhaar_authenticated_status'
                  value={formData.adhaar_authenticated_status}
                  labelId="gstin-uin-status-label"
                  onChange={handleChange}
                  variant="filled"
                >
                  <MenuItem value="True">Active</MenuItem>
                  <MenuItem value="False">Non-active</MenuItem>
                  <MenuItem value="Unknown">Unknown</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  name='taxpayer_type'
                  value={formData.taxpayer_type}
                  label="Taxpayer Type"
                  InputLabelProps={{
                    style: { color: 'white' }
                  }}
                  variant="filled"
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">

                <TextField
                  name='additional_trade_name'
                  value={formData.additional_trade_name}
                  label="Additional Trade Name"
                  InputLabelProps={{
                    style: { color: 'white' }
                  }}
                  variant="filled"
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <InputLabel id="gstin-uin-status-label" style={{
                  color: 'white',
                }}
                >E-kyc Status</InputLabel>

                <Select
                  name='ekyc_status'
                  value={formData.ekyc_status}
                  labelId="gstin-uin-status-label"
                  onChange={handleChange}
                  variant="filled"
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="non-active">Non-active</MenuItem>
                  <MenuItem value="unknown">Unknown</MenuItem>
                </Select>
              </FormControl>

            </Box> */}
            {/* <Box sx={{
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                width: '50%',
                paddingLeft: 8,
              },
              display: 'flex',
              flexDirection: 'column'
            }} >
              <Typography variant="subtitle1">Office Addresses</Typography> */}

            {/* <FormControl fullWidth margin="normal">
                <TextField
                  name='principle_place'
                  value={formData.principle_place}
                  label="Principal Place"

                  InputLabelProps={{
                    style: { color: 'white' }
                  }}
                  rows={4}
                  variant="filled"
                  multiline
                  onChange={handleChange}
                />
              </FormControl> */}

            {/* </Box> */}
          </Box>

          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              backgroundColor: "#3e4396",
              alignItems: "center",
              width: "30%",
              ml: "35%",
            }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
