import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";

import { useEffect, useState } from "react";
import api from "../axios";
import { useContext } from "react";
import AddAssetModal from "../components/AssetPage/AddAssetModal";
// import { useLocation, useParams } from "react-router-dom";
import DeliveryDetailsBox from "../components/OrderDetailsPage/DeliveryDetailsBox";
import OrderDetailsBox from "../components/OrderDetailsPage/OrderDetailsBox";
import Header from "../components/NewComponents/Header";
import HeaderButton from "../components/NewComponents/HeaderButton";
import { AddCircleOutline, Description } from "@mui/icons-material";
import { BriefcaseIcon } from "@heroicons/react/20/solid";
import InvoiceTable from "../components/InvoicePage/InvoiceTable";
import AddInvoiceModal from "../components/InvoicePage/AddInvoiceModal";
import AddPaymentModal from "../components/PaymentsPage/AddPaymentModal";


const Buttons = [
//   <HeaderButton
//     name="Add Invoice"
//     icon={<AddCircleOutline />}
//     // onClick={}
//   />

<AddInvoiceModal />
];

const SubDetails = [
  {
    title: "All Invoices",
    icon: (
      <BriefcaseIcon
        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
    ),
  },
];



const Invoices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);




  
  return (
    <Box sx={{ marginInline: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/* <Header title="Order Details" subtitle="" /> */}
        <Header title='Invoices' buttons={Buttons} subDetails={SubDetails}/>
        
      </Box>

      <Box>
        <InvoiceTable fetch_type='all' />
      </Box>
    </Box>
  );
};

export default Invoices;
