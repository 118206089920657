import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Pagination,
  useTheme,
  useMediaQuery,
  Typography,
  Box,
  FormControl,
} from "@mui/material";
import api from "../../axios";
import { tokens } from "../../theme";

import { AuthContext } from "../../AuthContext";

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import {
  LocalShipping,
  FormatListBulleted,
  Description,
  Opacity,
  Payment,
  Download,
  Done,
  Cancel,
  RemoveCircleOutline,
  Info,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Popover,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import AddDeliveryModal from "../OrdersPage/DeliveryModal";
import { useNavigate } from "react-router-dom";
import DownloadInvoiceButton from "./DownloadInvoice";
import EditInvoiceModal from "./EditInvoiceModal";

const OrdersTable = ({ fetch_type, order_type }) => {
  const [openDelivery, setOpenDelivery] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token, invoiceUpdateTrigger } = useContext(AuthContext);

  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    console.log(status);
  };

  const handleCloseDelivery = () => {
    setOpenDelivery(false);
  };

  const isMobile = useMediaQuery("(max-width : 600px)");
  useEffect(() => {
    fetchInvoices();
  }, [currentPage, invoiceUpdateTrigger]);

  const fetchInvoices = async () => {
    console.log(token);
    try {
      const response = await api.get("get-invoice", {
        params: {
          //   order_type: fetch_type === "single" ? order_type : "romulus",
          //   order_status: orderStatus,
          fetch_type: fetch_type,
          page: currentPage,
          // company_id: fetch_type === "single" ? comp_id : ""
        },
      });
      const { results, current_page, count } = response.data;
      setInvoiceData(results);
      setTotalPages(parseInt(count / 12) + 1);
    } catch (error) {
      if (error.response.status === 403) {
        window.location.reload();
      }
    }
  };

  const handleFilterChange = (status) => {
    setOrderStatus(status);
    setCurrentPage(1);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    console.log(totalPages);
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState("dateRange");
  const [selectedMonth, setSelectedMonth] = useState("June");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    // if (!selectedMethod) {
    //   return;
    // }

    if (selectedMethod === "dateRange") {
      if (!fromDate || !toDate) {
        toast.error("Please select from and to date.");
        return;
      }

      if (fromDate >= toDate) {
        toast.error("From date should be earlier than to date");
        return;
      }
    }

    let params = {};

    if (selectedMethod === "dateRange") {
      params = {
        company_id: token.company_id ? token.company_id : token.id,
        from_date: fromDate,
        to_date: toDate,
        filter_type: "date_range",
      };
    } else if (selectedMethod === "monthly") {
      params = {
        company_id: token.company_id ? token.company_id : token.id,
        month: selectedMonth,
        filter_type: "month",
      };
    }

    api
      .get("export-orders/", {
        params,
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "orders.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading orders:", error);
      });
  };

  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "download-popover" : undefined;

  const assetIcons = {
    datum: require("../../assets/datum.png"),
    truck: require("../../assets/truck.png"),
    InfraMachine: require("../../assets/citytech-logo.png"),
    DGset: require("../../assets/generator.png"),
    Industrial: require("../../assets/factory.png"),
    others: require("../../assets/oil-pump.png"),
  };

  const navigate = useNavigate();

  return (
    <div>
      <Box display="flex" justifyContent="space-between" spacing={1}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            sx={{
              ml: 1,
              bgcolor: colors.card["bg"],
              color: colors.pure[900],
              "&:hover": {
                backgroundColor: colors.card["hover"],
              },
            }}
            disabled
            onClick={() => handleFilterChange("")}
            startIcon={<FormatListBulleted />}
          >
            Date
          </Button>
        </Box>

        <Box display="flex" gap="1px">
          <div>
            <Button
              sx={{
                bgcolor: colors.card["bg"],
                color: colors.pure[900],
                "&:hover": {
                  backgroundColor: colors.card["hover"],
                },
              }}
              disabled
              onClick={() => handleFilterChange("")}
              startIcon={<Description />}
              onClick={handleClick}
            >
              Export to XLS
            </Button>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box padding="5px" display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMethod === "dateRange"}
                        onChange={handleMethodChange}
                        value="dateRange"
                      />
                    }
                    label="Date Range"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMethod === "monthly"}
                        onChange={handleMethodChange}
                        value="monthly"
                      />
                    }
                    label="Monthly"
                  />
                </Box>

                <Box>
                  {selectedMethod === "dateRange" && (
                    <div>
                      <input
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                      <input
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                      />
                    </div>
                  )}
                  {selectedMethod === "monthly" && (
                    <FormControl fullWidth>
                      <Select
                        value={selectedMonth}
                        onChange={handleMonthChange}
                      >
                        <MenuItem value="January">January</MenuItem>
                        <MenuItem value="February">February</MenuItem>
                        <MenuItem value="March">March</MenuItem>
                        <MenuItem value="April">April</MenuItem>
                        <MenuItem value="May">May</MenuItem>
                        <MenuItem value="June">June</MenuItem>
                        <MenuItem value="July">July</MenuItem>
                        <MenuItem value="August">August</MenuItem>
                        <MenuItem value="September">September</MenuItem>
                        <MenuItem value="October">October</MenuItem>
                        <MenuItem value="November">November</MenuItem>
                        <MenuItem value="December">December</MenuItem>

                        {/* Add more months */}
                      </Select>
                    </FormControl>
                  )}
                </Box>

                <Button startIcon={<Download />} onClick={handleDownload}>
                  Download XLS
                </Button>
              </Box>
            </Popover>
          </div>
        </Box>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          height: "57vh",
          marginBlock: 2,
          pt: 0,
          overflow: "auto",
          bgcolor: colors.card2["bg"],
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "600" }}>Invoice No.</TableCell>
              <TableCell style={{ fontWeight: "600" }}>Company</TableCell>
              <TableCell style={{ fontWeight: "600" }}>Date</TableCell>
              <TableCell style={{ fontWeight: "600" }}>Amount</TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* <Opacity sx={{ marginRight: "5px" }} /> */}
                  <span style={{ fontWeight: "600" }}>Payment Status</span>
                </Box>
              </TableCell>

              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* <Opacity sx={{ marginRight: "5px" }} /> */}
                  <span style={{ fontWeight: "600" }}>Order Location</span>
                </Box>
              </TableCell>

              <TableCell style={{ fontWeight: "600" }}>Options</TableCell>
              {/* <TableCell style={{ fontWeight: "600" }}>Details</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceData.map((invoice) => {
              return (
                <TableRow key={invoice.invoice_no}>
                  <TableCell>
                    <Typography fontWeight="600">
                      <span style={{ fontWeight: "700", fontSize: "15px" }}>
                        {invoice.invoice_no}
                      </span>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography fontWeight="600">
                      <span style={{ fontWeight: "700", fontSize: "15px" }}>
                        {invoice.company_name}
                      </span>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography fontWeight="600">
                      <span style={{ fontWeight: "700", fontSize: "15px" }}>
                        {invoice.invoice_date}
                      </span>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography fontWeight="600">
                      {/* {formatDate(invoice.amount)} */}₹ {invoice.amount}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        display: "flex",
                        alignItems: "center",
                        color:
                          invoice.payment_status === "Pending"
                            ? "firebrick"
                            : invoice.payment_status === "Partial"
                            ? "chocolate"
                            : "green",
                      }}
                    >
                      {/* <Opacity style={{ fontWeight: "600", fontSize: "20px" }} /> */}
                      {invoice.payment_status}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <Opacity style={{ fontWeight: "600", fontSize: "20px" }} /> */}
                      {invoice.destination}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <div className="flex flex-row gap-1">
                      <DownloadInvoiceButton fileUrl={invoice.presigned_url} />
                      <Button
                        onClick={() => {
                          navigate(`/order-details/${invoice.order}`);
                        }}
                        variant="contained"
                        sx={{ bgcolor: "darkcyan" }}
                      >
                        Order <InfoOutlined />
                      </Button>
                      <EditInvoiceModal invoiceData={invoice} />
                      {/* <Button
                        disabled
                        //   onClick={() => {
                        //     navigate(`/order-details/${order.id}`);
                        //   }}
                        variant="contained"
                        sx={{ bgcolor: "firebrick" }}
                      >
                        <RemoveCircleOutline />
                      </Button> */}
                    </div>
                  </TableCell>

                  {/* <TableCell></TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {openDelivery ? <AddDeliveryModal onClose={handleCloseDelivery} /> : ""}
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        showFirstButton
        showLastButton
        siblingCount={2}
        boundaryCount={2}
      />
    </div>
  );
};

export default OrdersTable;
