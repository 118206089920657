import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { PencilIcon } from "@heroicons/react/20/solid";
import DatePicker from "react-datepicker"; // Import date picker library
import "react-datepicker/dist/react-datepicker.css"; // Import date picker styles
import { useEffect } from "react";
import api from "../../axios";
import { toast } from "react-toastify";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EditInvoiceModal({ invoiceData }) {

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [invoiceNo, setInvoiceNo] = useState('');


  const [selectedDate, setSelectedDate] = useState(null);

  const [locationData, setLocationData] = useState([])

  



  const handleSave = () => {
    // Perform API request to update the order with new data
    
    
    const details = {
        invoice:invoiceData.id,
        invoice_no : invoiceNo,
        invoice_date : selectedDate
    }
    api.put('invoice',details).then((res)=>{
        toast.success('Updated Successfully')
        window.location.reload()
    })

    // handleClose();
  };

//   useEffect(() => {
//     if (orderData.created_at) {
//       const parsedDate = new Date(orderData.created_at);
//       setSelectedDate(parsedDate);
//     }
//   }, [orderData.created_at]);


  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().split("T")[0]; // Convert to "YYYY-MM-DD" format
      setSelectedDate(formattedDate);
    } else {
      setSelectedDate(null);
    }
  };
  return (
    <>
      <button
        onClick={handleOpen}
        type="button"
        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <PencilIcon
          className="-ml-0.5 mr-0.5 h-5 w-5 text-blue-400"
          aria-hidden="true"
        />
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2">
            Edit Invoice Details
          </Typography>
          <div className="mt-3">
            <label className="block text-sm font-medium text-gray-700">
              Invoice No || current:{invoiceData?.invoice_no}
            </label>
            <input
              placeholder={invoiceData.invoice_no}
              type="text"
              value={invoiceNo}
              onChange={(e) => setInvoiceNo(e.target.value)}
              className="mt-1 p-2 block w-full rounded-md border border-gray-300"
            />
          </div>

          <div className="mt-3">
            {/* Date */}
            <label className="block text-sm font-medium text-gray-700">
              Date || current:{invoiceData.invoice_date}
            </label>
            {/* <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              placeholderText={orderData.created_at}
              dateFormat="MMMM d, yyyy" // Format for the input placeholder
              className="mt-1 p-2 block w-full rounded-md border border-gray-300"
              isClearable={true}
            /> */}
            {/* {selectedDate !== null ? (
              
            ) : ( */}

            {/* <DatePicker
              placeholderText="Change Date"
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"
              className="mt-1 p-2 block w-full rounded-md border border-gray-300"
              isClearable={true}
            /> */}

            <input
                type="date"
                placeholder='Change date'
                value={selectedDate}
                onChange={(e)=>setSelectedDate(e.target.value)}
                className="mt-1 p-2 block w-full rounded-md border border-gray-300"    
              />

            {/* )} */}
          </div>
          <div className="mt-3">
            {/* Save and Cancel buttons */}
            <button
              onClick={handleSave}
              className="px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-600"
            >
              Save
            </button>
            <button
              onClick={handleClose}
              className="ml-2 px-4 py-2 text-sm font-semibold text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
