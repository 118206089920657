import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  useMediaQuery,
  Grid
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import api from "../../axios";
import { AuthContext } from "../../AuthContext";
import { Add, HdrPlus, LocalGasStation, PlusOne } from "@mui/icons-material";
import { toast } from "react-toastify";
// import Lordicon from "react-lordicon";
// import lottie from "lottie-web";
// import { defineElement } from "lord-icon-element";

// import { toast } from "react-toastify";

const AddOrderModal = ({ company }) => {
  
  // defineElement(lottie.loadAnimation);
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [asset, setAsset] = useState("");
  const [orderType, setOrderType] = useState("romulus");
  const [bypassTotalizer, setBypassTotalizer] = useState(false);
  const [total, setTotal] = useState("");

  // const [location2,setLocation2] = useState('');
  // const dieselPrice = 92.5

  // const isMobile = useMediaQuery("(max-width : 600px)");
  const [dieselPrice, setDieselPrice] = useState(90)
  const [discountPrice, setDiscountPrice] = useState(1)
  const {token} = useContext(AuthContext)

  const handleQty = (qty) => {
    console.log('.....................',qty);
    setQuantity(qty)

    setTotal((parseFloat(dieselPrice) * qty) - (parseFloat(discountPrice) * qty)); 
  }
  
  const { selectedCompany,setUpdateOrder,updateOrder } = useContext(AuthContext);
  const company_id = selectedCompany.id;
  const isMobile = useMediaQuery("(max-width : 600px)");
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleAddOrder

  //   const handleAddLocation = () => {
  //     api.post("location", { company_id, location }).then((response) => {
  //       console.log(response.data);
  //       getLocations();
  //     });
  //     setLocation("");
  //   };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  

  useEffect(() => {
    getDieselPrice()
    getAssetData();
  }, []);

  const [assetData, setAssetData] = useState([]);

  const getDieselPrice = async () =>{
    await api.get(`get-diesel-price/${company_id}`).then((response)=>{
      console.log('rrrrrrrrrr',response.data);
      setDieselPrice(response.data.diesel_price)
      setDiscountPrice(response.data.discount?response.data.discount:0)
      setLocationData(response.data.locations)
    })
  }

  const getAssetData = async () => {
    await api.get(`assets/${company_id}`).then((response) => {
      setAssetData(response.data);
    });
  };

  const assetIcons = {
    All: require("../../assets/industry.png"),
    Datum: require("../../assets/datum.png"),
    HeavyVehicle: require("../../assets/truck.png"),
    InfraMachine: require("../../assets/citytech-logo.png"),
    DgSet: require("../../assets/generator.png"),
    Industrial: require("../../assets/factory.png"),
    Others: require("../../assets/oil-pump.png"),
  };

  const [userData, setUserData] = React.useState([]);

const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = () => {
    
    setIsLoading(true)
    const details = {
      asset: asset,
      requested_quantity: quantity,
      company: company_id,
      ordered_by: company_id,
      ordered_user_type: "company",
      diesel_price: dieselPrice,
      discount_price: discountPrice,
      requested_total_price: total,
      order_status: "Ordered",
      order_type: orderType,
      bypass_totalizer: bypassTotalizer,
      by_admin:true,
      ordered_admin:token.id,
      location:location
    };
    console.log(details);
    api.post('order',details).then((response)=>{
      setUpdateOrder(!updateOrder)
      handleClose()
      toast.success(
        <Box display="flex" alignItems="center">
          <lord-icon
            src="https://cdn.lordicon.com/tqywkdcz.json"
            trigger="loop"
            delay="500"
            colors="primary:#4bb3fd,secondary:#f28ba8,tertiary:#ffc738,quaternary:#f24c00"
            style={{ width: "64px", height: "64px", marginRight: "10px" }}
          />
          Order Placed Successfully
        </Box>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000, // Auto-close duration in milliseconds
        }
      );
    }).catch((error)=>{
      console.log('====================================');
      console.log(error);
      console.log('====================================');
      toast.error(error.response.data.message)
      if (error.response.status === 409){
        console.log('====================================');
        console.log('ttttt');
        console.log('====================================');
        window.location.reload()
      }
    }).finally(()=>{
      setIsLoading(false);
    })
  }

      const [location, setLocation] = useState("");
const [locationData, setLocationData] = useState([]);

      const handleLocationChange = (event) => {
        setLocation(event.target.value);
      };


  return (
    <div>
      <Button onClick={handleOpen} color="primary">
        <Add /> Add Order
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: isMobile ? "90vw" : "70vw",
          }}
        >
          {/* <Box display='flex' gap='4px' flexDirection='column'>
            <Box
              sx={{
                
                bgcolor: colors.grey[900],
                borderRadius: "4px",
                p: 2,
                color: "black",
              }}
            >
              <Typography sx={{color:colors.grey[100]}}>Location 1&nbsp;:&nbsp;</Typography>
              <Typography>
                {data?.location}
              </Typography>
              <Button variant='contained'>Delete</Button>
            </Box>

            {data?.location2 !== ''?
            
            <Box
              sx={{
                bgcolor: colors.grey[900],
                borderRadius: "4px",
                p: 2,
                color: "black",
              }}
            >
              <Typography sx={{color:colors.grey[100]}}>Location 2&nbsp;:&nbsp;</Typography>
              <Typography>
                
              </Typography>
              <Button variant='contained'>Delete</Button>
            </Box>
            :''}

          </Box> */}

          {/* Add location section */}

          <Typography
            sx={{ fontWeight: "600", textAlign: "center" }}
            variant="h3"
            gutterBottom
          >
            Add Order
          </Typography>

          {/* <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={age}
              label="Age"
              // onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl> */}

          <FormGroup
            sx={{ border: "solid 1px", borderRadius: "3px", mb: 1, pl: 1 }}
          >
            <Typography fontWeight="600">Select Order Type</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    setOrderType("client");
                  }}
                  checked={orderType === "client"}
                />
              }
              label="Client Order"
            />
            <FormControlLabel
              control={
                <Checkbox
                  // disabled
                  onChange={() => {
                    setOrderType("romulus");
                    setAsset("");
                  }}
                  checked={orderType === "romulus"}
                />
              }
              label="Romulus Order"
            />
          </FormGroup>

          <div className="relative">
            <select
              value={location}
              onChange={handleLocationChange}
              className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-indigo-500 focus:shadow-outline"
            >
              <option value="">Select a location</option>

              {locationData.map((obj) => (
                <option value={obj.id}>{obj.location}</option>
              ))}

              {/* Add more options as needed */}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              {/* Custom location icon */}
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M10 0C4.477 0 0 4.477 0 10c0 3.42 1.998 6.323 4.876 7.691.213.078.457-.018.534-.231.075-.21-.019-.455-.231-.534C2.916 15.444 2 12.862 2 10 2 5.364 5.364 2 10 2s8 3.364 8 8c0 2.862-.916 5.444-2.179 7.926-.212.52.251 1.047.788.874C18.546 16.323 20 13.42 20 10 20 4.477 15.523 0 10 0zm0 18c-3.314 0-6-2.686-6-6 0-3.314 2.686-6 6-6 3.314 0 6 2.686 6 6 0 3.314-2.686 6-6 6z"></path>
              </svg>
            </div>
          </div>

          <TextField
            sx={{ mt: 1 }}
            label="Quantity"
            value={quantity}
            // onChange={handleLocationChange}
            onChange={(e) => {
              handleQty(e.target.value);
            }}
            fullWidth
          />

          <FormControl fullWidth margin="normal">
            <InputLabel id="asset-state-label">Select an asset</InputLabel>
            <Select
              disabled={orderType === "romulus"}
              name="asset_incharge"
              value={asset}
              onChange={(e) => setAsset(e.target.value)}
              variant="filled"
            >
              {assetData.map((obj) => {
                let assetIcon = assetIcons["All"];
                if (obj.typeOfAsset === "Datum") {
                  assetIcon = assetIcons["Datum"];
                } else if (obj.typeOfAsset === "Heavy vehicle") {
                  assetIcon = assetIcons["HeavyVehicle"];
                } else if (obj.typeOfAsset === "Infra machine") {
                  assetIcon = assetIcons["InfraMachine"];
                }
                return (
                  <MenuItem value={obj.id}>
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      gap="5px"
                      justifyContent="space-between"
                    >
                      <Box display="flex" flexDirection="row" gap="6px">
                        <img
                          src={assetIcon}
                          style={{ filter: colors.pngcol["filter"] }}
                          width="35"
                          height="35"
                        />
                        <Box
                          display="flex"
                          flexDirection="column"
                          marginTop="-3px"
                        >
                          <Typography fontWeight="700">
                            {obj.assetName}
                          </Typography>

                          <Typography fontSize="13px">
                            {obj.typeOfAsset}
                          </Typography>
                        </Box>
                      </Box>

                      <Box marginTop="-3px" width="25%" paddingLeft="5px">
                        <Typography>
                          {obj.assetRegistrationNumber !== ""
                            ? `Reg No : ${obj.assetRegistrationNumber}`
                            : `Capacity : ${obj.assetCapacity} Ltrs`}
                        </Typography>

                        {/* <Typography variant="p" fontSize="14px">
                          Location:dfsdfsd
                        </Typography> */}
                      </Box>
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <FormGroup
            sx={{ border: "solid 1px", borderRadius: "3px", mb: 1, pl: 1 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    setBypassTotalizer(!bypassTotalizer);
                  }}
                  checked={bypassTotalizer}
                />
              }
              label="Bypass Totalizer Check" 
            />
          </FormGroup> */}

          {/* <Box sx={{border:'solid 1px',p:1}}>
            <Typography variant='h5'>Diesel Price : 92.5</Typography>
            <Typography variant='h5'>Total Cost : 92.5</Typography>
          </Box> */}
          {/* <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            >
            Add
          </Button>
            </Box> */}
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="row"
              alignItems="center"
              sx={{
                border: "solid 1px",
                p: 1,
                borderRadius: 1,
                mb: 1,
                width: !isMobile ? "50%" : "",
              }}
            >
              <LocalGasStation sx={{ fontSize: "45px" }} />
              <Grid container alignItems="center" spacing={1}>
                <Grid item sm={6}>
                  <Typography fontSize="15px">Diesel Price:</Typography>
                  <Typography fontSize="15px">Discount Price:</Typography>
                  <Typography fontSize="15px">Sub Total:</Typography>
                  <Typography fontSize="15px">Total Discount:</Typography>
                  <Typography fontSize="15px" fontWeight="700">
                    Total Amount:
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${dieselPrice}/Ltr`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${discountPrice}/Ltr`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${quantity * dieselPrice}`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`- Rs. ${quantity * discountPrice}`}
                  </Typography>
                  <Typography
                    fontSize="15px"
                    fontWeight="700"
                    textAlign="right"
                  >
                    <span
                      style={{
                        backgroundColor: "blue",
                        borderRadius: "5px",
                        paddingInline: "4px",
                        color: "white",
                      }}
                    >{`Rs. ${total}`}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              disabled={isLoading}
              onClick={handleSubmit}
              size="large"
              variant="contained"
            >
              {/* <lord-icon trigger="hover" src="/my-icon.json"></lord-icon> */}

              {/* <Lordicon
                  colors={{
                    primary: "#fff",
                    secondary: "#222",
                  }}
                icon='helpCenter'
                size={40}
                delay={1000}
                trigger="loop"
              /> */}

              {!isLoading ? "Order Now" : "Ordering Please wait...."}
              {isLoading && (
                <lord-icon
                  src="https://cdn.lordicon.com/jyijxczt.json"
                  trigger="loop"
                  delay="500"
                  colors="primary:#3a3347,secondary:#ffc738,tertiary:#848484,quaternary:#646e78"
                  style={{ width: "50px", height: "50px" }}
                ></lord-icon>
              )}
            </Button>
            <div style={{ background: "black", height: "10px" }}></div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AddOrderModal;
