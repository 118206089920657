import React from 'react'
import DieselPieChart from '../components/HomePage/DieselBarChart';
import DieselHourlyLineChart from '../components/HomePage/DieselLineChart';
import DashboardTable from '../components/HomePage/DashboardTable';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';

function Dashboard() {
    const theme = useTheme();
  const colors = tokens(theme.palette.mode);

    const dieselData = [
        { day: 'Day 1', amount: 100 },
        { day: 'Day 2', amount: 80 },
        { day: 'Day 3', amount: 120 },
        { day: 'Day 4', amount: 90 },
        { day: 'Day 5', amount: 70 },
        { day: 'Day 6', amount: 110 },
        { day: 'Day 7', amount: 95 },
        { day: 'Day 8', amount: 130 },
        { day: 'Day 9', amount: 85 },
        { day: 'Day 10', amount: 105 },
        { day: 'Day 11', amount: 75 },
        { day: 'Day 12', amount: 115 },
        { day: 'Day 13', amount: 100 },
        { day: 'Day 14', amount: 90 },
        { day: 'Day 15', amount: 120 },
        { day: 'Day 16', amount: 80 },
        { day: 'Day 17', amount: 110 },
        { day: 'Day 18', amount: 95 },
        { day: 'Day 19', amount: 130 },
        { day: 'Day 20', amount: 70 },
        { day: 'Day 21', amount: 105 },
        { day: 'Day 22', amount: 85 },
        { day: 'Day 23', amount: 115 },
        { day: 'Day 24', amount: 100 },
        { day: 'Day 25', amount: 120 },
        { day: 'Day 26', amount: 90 },
        { day: 'Day 27', amount: 75 },
        { day: 'Day 28', amount: 110 },
        { day: 'Day 29', amount: 95 },
        { day: 'Day 30', amount: 130 },
      ];
      

    return (
        <div className='p-3'>
            <div className='w-full flex flex-col md:flex-row justify-center gap-10 align-center'>
                <div className='w-[70%] gap-3 flex flex-col h-[70vh] justify-center align-center'>

                    <div className='flex flex-col md:flex-row gap-5 w-full h-full p-2'>

                        <div className={`w-full h-full ${colors.card2['bg']} rounded-lg`}>
                            {/* ewfsdfs */}
                            <DieselHourlyLineChart data={dieselData} />
                        </div>

                        {/* <div className='w-full h-full bg-gray-100 rounded-lg'>
                            dfsdf
                        </div> */}

                    </div>

                    <div className='flex flex-row gap-5 w-full h-full p-2'>

                        <div className='w-full bg-gray-100 rounded-lg'>
                        <DieselPieChart data={dieselData} />
                        </div>

                            {/* <div className='w-full bg-gray-100 rounded-lg'>
                                dfsdf
                            </div> */}

                    </div>

                </div>

                <div className='w-full h-auto p-2 '>
                    <DashboardTable/>
                </div>
            </div>

        </div>
    )
}

export default Dashboard