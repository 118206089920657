import { useTheme } from '@mui/material';
import React from 'react';
import { tokens } from '../../theme';
import { Close, Done, Pending } from '@mui/icons-material';

const CompanyTable = () => {
  // Sample data for demonstration purposes
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const companies = [
    { name: 'Company A', paymentCompleted: 10, paymentPending: 5 },
    { name: 'Company B', paymentCompleted: 15, paymentPending: 3 },
    { name: 'Company A', paymentCompleted: 10, paymentPending: 5 },
    { name: 'Company B', paymentCompleted: 15, paymentPending: 3 },
    { name: 'Company A', paymentCompleted: 10, paymentPending: 5 },
    { name: 'Company B', paymentCompleted: 15, paymentPending: 3 },
    { name: 'Company A', paymentCompleted: 10, paymentPending: 5 },
    { name: 'Company B', paymentCompleted: 15, paymentPending: 3 },
    { name: 'Company A', paymentCompleted: 10, paymentPending: 5 },
    { name: 'Company B', paymentCompleted: 15, paymentPending: 3 },
    { name: 'Company A', paymentCompleted: 10, paymentPending: 5 },
    { name: 'Company B', paymentCompleted: 15, paymentPending: 3 },
    { name: 'Company A', paymentCompleted: 10, paymentPending: 5 },
    { name: 'Company B', paymentCompleted: 15, paymentPending: 3 },
    
    // Add more company data here
  ];

  return (
    <div 
    style={{
        boxShadow:"0 2px 6px rgba(0, 0, 0, 0.16)",
        borderColor:colors.grey[900]

}}
    className="w-full h-auto max-h-[68.5vh] overflow-auto border border-gray-200 rounded-md"> {/* Adjust the width and height as needed */}
    <table className="w-full border-collapse">
      <thead className='sticky top-0'>
        <tr 
        style={{background:'rgb(0, 120, 212)'}}
        className="bg-blue-500">
          <th className="py-2 px-4 text-center text-white">Company</th>
          <th className="py-2 px-4 text-center text-white"> <Done/>Payment Completed  </th>
          <th className="py-2 px-4 text-center text-white font-bold"> <Close/> Payment Pending</th>
        </tr>
      </thead>
      <tbody>
        {companies.map((company, index) => (
          <tr key={index} 
          style={{
            background:index % 2 === 0 ? colors.card2['bg']:colors.card2['bg']
          }}
          className={`h-20 border-b border-gray-300`}
          >
            <td className="py-4 px-4 text-center font-semibold">{company.name}</td>
            <td className="py-4 px-4 text-center  font-bold "><span className='bg-green-600 p2 px-2 text-[1rem] text-white rounded-lg'>₹{company.paymentCompleted}0000.00</span></td>
            <td className="py-4 px-4 text-center font-bold"> <span className='bg-red-600 p2 px-2 text-[1rem] text-white rounded-md'>₹{company.paymentPending}0000.00</span></td>
            <hr></hr>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  );
};

export default CompanyTable;
