import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useMediaQuery from "@mui/material/useMediaQuery";
import CompanyDetailModal from "../Modals/CompanyDetailsModal";
import api from "../../axios";
import { toast } from "react-toastify";
import {
  MoreVertOutlined,
  Payments,
  LocalShipping,
  Settings,
  Tune,
} from "@mui/icons-material";
import AddLocationModal from "../Modals/LocationModal";
import OrdersModal from "../Modals/OrdersModal";
import TransactionsModal from "../Modals/TransactionsModal";
import DieselPriceModal from '../Modals/DieselPrice'
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";

export default function StickyHeadTable(props) {
  const { setTableRow, selectedCompany, setSelectedCompany } =
    useContext(AuthContext);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (id) => {
    api
      .delete(`company/${selectedCompany.id}`)
      .then((response) => {
        handleClose();
        props.updateTable();
        toast.success("Deleted Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred.Please Try again", error);
      });
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, company) => {
    setSelectedCompany(company);
    setAnchorEl(event.currentTarget);
    console.log(company);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectCompany = (row) => {
    console.log(row, "11111111111111111111");
  };

  const open = Boolean(anchorEl);
  const id = open ? "more-popover" : undefined;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#383a59", color: "white" }}>
                Username
              </TableCell>
              {/* <TableCell sx={{ backgroundColor: "#383a59", color: "white" }}>
                Trade Name
              </TableCell>
              <TableCell sx={{ backgroundColor: "#383a59", color: "white" }}>
                GSTIN
              </TableCell> */}
              <TableCell sx={{ backgroundColor: "#383a59", color: "white" }}>
                Number
              </TableCell>
              {/* <TableCell sx={{ backgroundColor: "#383a59", color: "white" }}>
                This Month Purchase
              </TableCell> */}

              {/* <TableCell sx={{ backgroundColor: "#383a59", color: "white" }}>
                Outstanding Amount
              </TableCell> */}

              <TableCell sx={{ backgroundColor: "#383a59", color: "white" }}>
                {/* More Info */}
              </TableCell>
              {/* <TableCell sx={{ backgroundColor: '#383a59', color: 'white' }}>
                                Edit
                            </TableCell>
                            <TableCell sx={{ backgroundColor: '#383a59', color: 'white' }}>
                                Delete
                            </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: colors.card2["bg"] }}>
            {props.rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  <TableCell sx={{ fontSize: "0.9rem" }} align="left">
                    {row.username}
                  </TableCell>
                  {/* <TableCell sx={{ fontSize: "0.9rem" }} align="left">
                    {row.company_info?.trade_name}
                  </TableCell> */}
                  {/* <TableCell sx={{ fontSize: "0.9rem" }} align="left">
                    {row.company_info?.gstin}
                  </TableCell> */}
                  <TableCell sx={{ fontSize: "0.9rem" }} align="left">
                    {row.number}
                  </TableCell>
                  {/* <TableCell sx={{ fontSize: "0.9rem" }} align="left">
                    {`${row.company_info?.monthly_purchase_quantity} Ltrs  /  ₹ ${row.company_info?.monthly_purchase_cost}`}
                  </TableCell> */}

                  {/* <TableCell sx={{ fontSize: "0.9rem" }} align="left">
                    ₹ {row.company_info?.total_outstanding}
                  </TableCell> */}

                  <TableCell align="left">
                    {/* <Button variant="contained" sx={{ bgcolor: '#1976d2', color: 'white',
                                    '&:hover': {
                                        bgcolor: '#115293',
                                      },
                                      ...(isMobile && {
                                        minWidth: 30,
                                        padding: '4px 8px',
                                        fontSize: 11,
                                      }),
                                    }}>
                                        Details
                                        </Button> */}

                    {/* <Button
                      variant="contained"
                      sx={{
                        bgcolor: "#1976d2",
                        color: "white",
                        "&:hover": {
                          bgcolor: "#115293",
                        },
                        ...(isMobile && {
                          minWidth: 30,
                          padding: "4px 8px",
                          fontSize: 11,
                        }),
                      }}
                    >
                      <EditIcon />
                      More
                    </Button> */}

                    <Button
                      // onClick={()=>{setTableRow}}
                      variant="contained"
                      onClick={(e) => handleClick(e, row)}
                      sx={{
                        bgcolor: "#1976d2",
                        color: "white",
                        "&:hover": {
                          bgcolor: "#115293",
                        },
                        ...(isMobile && {
                          minWidth: 30,
                          padding: "4px 8px",
                          fontSize: 11,
                        }),
                      }}
                    >
                      <Tune /> Options
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <List>
                        <ListItem
                          button
                          onClick={() => handleSelectCompany(row)}
                        >
                          {/* <ListItemIcon><EditIcon/></ListItemIcon>
                            <ListItemText primary='Details' /> */}
                          <CompanyDetailModal data={row} />
                        </ListItem>

                        <ListItem button onClick={() => handleDelete(row.id)}>
                          <ListItemIcon>
                            <DeleteForeverIcon sx={{ color: "red" }} />
                          </ListItemIcon>
                          <ListItemText
                            sx={{ color: "red" }}
                            primary="Delete"
                          />
                        </ListItem>

                        {/* <ListItem button >
                            <ListItemIcon><EditIcon/></ListItemIcon>
                            <ListItemText primary='Edit' />
                          </ListItem> */}

                        <ListItem button>
                          {/* <ListItemIcon><LocationOnIcon/></ListItemIcon>
                            <ListItemText secondary='Locations' /> */}

                          <AddLocationModal company={row.id} />
                        </ListItem>

                        <ListItem button>
                          <DieselPriceModal updateTable={props.updateTable} company={row.id} />
                        </ListItem>

                        <ListItem button>
                          {/* <ListItemIcon><LocalShipping/></ListItemIcon>
                            <ListItemText secondary='Orders' /> */}

                          <OrdersModal company={row.id} />
                        </ListItem>

                        <ListItem button>
                          {/* <ListItemIcon><Payments/></ListItemIcon>
                            <ListItemText secondary='Payments' /> */}
                          <TransactionsModal
                            updateTable={props.updateTable}
                            outstanding_amount={row.total_outstanding}
                            company={row.id}
                          />
                        </ListItem>
                      </List>
                    </Popover>

                    {/* <CompanyDetailModal data={row} /> */}
                  </TableCell>

                  {/* <TableCell align="left">
                                        <Button variant="contained" sx={{ bgcolor: '#1976d2', color: 'white',
                                    '&:hover': {
                                        bgcolor: '#115293',
                                      },
                                      ...(isMobile && {
                                        minWidth: 30,
                                        padding: '4px 8px',
                                        fontSize: 11,
                                      }),
                                    }}>
                                            <EditIcon />
                                        </Button>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Button 
                                        onClick={()=>handleDelete(row.id)}
                                        variant="contained" 
                                        sx={{ 
                                            bgcolor:'firebrick',
                                            fontSize:'3rem',
                                            color:'white',
                                            '&:hover': {
                                                bgcolor: 'red',
                                                },
                                      ...(isMobile && {
                                        minWidth: 30,
                                        padding: '4px 8px',
                                        fontSize: 11,
                                      })
                                    }}>
                                            <DeleteForeverIcon
                                        //     sx={{
                                        //         fontSize:'1.7rem',
                                        //         color:'firebrick',
                                        //         '&:hover': {
                                        //             color: 'red',
                                        //           },
                                        //   ...(isMobile && {
                                        //     minWidth: 30,
                                        //     padding: '4px 8px',
                                        //     fontSize: 11,
                                        //   })
                                        // }}
                                            />
                                        </Button>
                                    </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ backgroundColor: colors.blueAccent[900] }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
