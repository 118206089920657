// export const userBaseurl = 'http://localhost:8000/user/'
// export const adminBaseurl = 'http://localhost:8000/'

// export const adminBaseurl = 'https://liveserver.romulusoil.com/'
// export const userBaseurl = 'https://liveserver.romulusoil.com/user/'

const adminBaseurl = process.env.REACT_APP_ADMIN_BASEURL;
console.log('====================================');
console.log(adminBaseurl);
console.log('====================================');
const userBaseurl = process.env.REACT_APP_USER_BASEURL;