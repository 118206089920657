import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { AccountCircleOutlined, CreditCard, Description, DescriptionOutlined, LocalGasStationOutlined, LocalShipping, LocalShippingOutlined, ShoppingBagOutlined, Work, WorkOffOutlined, WorkOutlineOutlined, WorkOutlined } from "@mui/icons-material";
import { DocumentIcon, HomeModernIcon } from "@heroicons/react/20/solid";

const Item = ({ title, to, icon, selected, setSelected, setIsHidden }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSelect = (title) => {
    localStorage.setItem("selected", title);
    setSelected(title);
    if (window.innerWidth <= 600) {
      setIsHidden(true);
    }
  };

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => handleSelect(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState(localStorage.getItem("selected"));
  const isMobile = window.innerWidth <= 600;
  const [isHidden, setIsHidden] = useState(isMobile ? true : false);

  const handleCollapse = () => {
    if (isMobile) {
      setIsHidden(true);
      setIsCollapsed(false);
    } else {
      setIsCollapsed(!isCollapsed);
    }
  };

  return (
    <>
      {isHidden === false ? (
        <Box
          sx={{
            "& .pro-sidebar-inner": {
              background: `${colors.primary[400]} !important`,
            },
            "& .pro-icon-wrapper": {
              backgroundColor: "transparent !important",
            },
            "& .pro-inner-item": {
              padding: "5px 35px 5px 20px !important",
            },
            "& .pro-inner-item:hover": {
              color: "#868dfb !important",
            },
            "& .pro-menu-item.active": {
              color: "#6870fa !important",
            },
            position: isMobile ? "absolute" : "relative",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          }}
        >
          <ProSidebar collapsed={isCollapsed}>
            <Menu iconShape="square">
              {/* LOGO AND MENU ICON */}
              <MenuItem
                onClick={() => handleCollapse()}
                icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                style={{
                  margin: "10px 0 20px 0",
                  color: colors.grey[100],
                }}
              >
                {!isCollapsed && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    ml="15px"
                  >
                    <Typography variant="h3" color={colors.grey[100]}>
                      Admin
                    </Typography>
                    {/* SUBCONTRACT */}
                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                      <MenuOutlinedIcon />
                    </IconButton>
                  </Box>
                )}
              </MenuItem>

              <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                <Item
                  title="Dashboard"
                  to="/"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />

                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Data
                </Typography>

                <Item
                  title="Company"
                  to="/company"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />

                <Item
                  title="Sub Contracts"
                  to="/sub-contracts"
                  icon={<WorkOutlineOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />

                <Item
                  title="Orders"
                  to="/orders"
                  icon={<ShoppingBagOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />

                <Item
                  title="Romulus Assets"
                  to="/assets"
                  icon={<LocalShippingOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />

                <Item
                  title="Employees"
                  to="/staff"
                  icon={<AccountCircleOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />

                <Item
                  title="Invoices"
                  to="/invoices"
                  icon={<DescriptionOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />

                <Item
                  title="Payments"
                  to="/payments"
                  icon={<CreditCard />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />

                {/* <Item
              title="Contacts Information"
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              setIsHidden={setIsHidden}
            /> */}
              </Box>
            </Menu>
          </ProSidebar>
        </Box>
      ) : (
        <MenuOutlinedIcon
          sx={{ position: "absolute", m: 3 }}
          onClick={() => setIsHidden(!isHidden)}
        />
      )}
    </>
  );
};

export default Sidebar;
