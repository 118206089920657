import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { PencilIcon } from "@heroicons/react/20/solid";
import DatePicker from "react-datepicker"; // Import date picker library
import "react-datepicker/dist/react-datepicker.css"; // Import date picker styles
import { useEffect } from "react";
import api from "../../axios";
import { toast } from "react-toastify";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EditOrderModal({ orderData }) {

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [approvedQty, setApprovedQty] = useState('');
  const [requestedQty, setRequestedQty] = useState(
    ''
  );
  const [selectedLocation, setSelectedLocation] = useState('');

  const [selectedDate, setSelectedDate] = useState(null);

  const [locationData, setLocationData] = useState([])

  

    const getLocations = async () => {
        console.log('====================================');
        console.log(orderData);
        console.log('====================================');
        if (orderData.company !== undefined){
            await api.get(`location/${orderData.company}`).then((response) => {
                setLocationData(response.data);
            });
        }
    };

  const handleSave = () => {
    // Perform API request to update the order with new data
    
    
    const details = {
        order:orderData.id,
        requested_quantity : requestedQty,
        approved_quantity : approvedQty,
        location : selectedLocation === orderData.location.id.toString()?'':selectedLocation,
        created_at : selectedDate
    }
    api.put('order-details',details).then((res)=>{
        toast.success('Updated Successfully')
        window.location.reload()
    })

    // handleClose();
  };

//   useEffect(() => {
//     if (orderData.created_at) {
//       const parsedDate = new Date(orderData.created_at);
//       setSelectedDate(parsedDate);
//     }
//   }, [orderData.created_at]);

  useEffect(() => {
    getLocations()
  }, [orderData]);

  return (
    <>
      <button
        onClick={handleOpen}
        type="button"
        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <PencilIcon
          className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        Edit
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2">
            Edit Order Details
          </Typography>
          <div className="mt-3">
            <label className="block text-sm font-medium text-gray-700">
              Approved Quantity || current:{orderData?.approved_quantity} Ltrs
            </label>
            <input
              placeholder={orderData.approved_quantity}
              type="text"
              value={approvedQty}
              onChange={(e) => setApprovedQty(e.target.value)}
              className="mt-1 p-2 block w-full rounded-md border border-gray-300"
            />
          </div>
          <div className="mt-3">
            {/* Requested Quantity */}
            <label className="block text-sm font-medium text-gray-700">
              Requested Quantity || current:{orderData?.requested_quantity} Ltrs
            </label>
            <input
              type="text"
              placeholder={orderData.requested_quantity}
              value={requestedQty}
              onChange={(e) => setRequestedQty(e.target.value)}
              className="mt-1 p-2 block w-full rounded-md border border-gray-300"
            />
          </div>

          <div className="mt-3">
            {/* Location */}
            <label className="block text-sm font-medium text-gray-700">
              Location || current:{orderData?.location?.location}
            </label>
            <select
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              className="mt-1 p-2 block w-full rounded-md border border-gray-300"
            >
              {/* Render the default selected location */}
              <option value={orderData?.location?.id}>
                {orderData?.location?.location}
              </option>

              {/* Render options based on locationData */}
              {locationData.map((loc) => {
                if (orderData?.location.id !== loc.id) {
                  return (
                    <option key={loc.id} value={loc.id}>
                      {loc.location}
                    </option>
                  );
                } else {
                  return null;
                }
              })}
            </select>
          </div>

          <div className="mt-3">
            {/* Date */}
            <label className="block text-sm font-medium text-gray-700">
              Date || current:{orderData.created_at}
            </label>
            {/* <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              placeholderText={orderData.created_at}
              dateFormat="MMMM d, yyyy" // Format for the input placeholder
              className="mt-1 p-2 block w-full rounded-md border border-gray-300"
              isClearable={true}
            /> */}
            {/* {selectedDate !== null ? (
              
            ) : ( */}

            <DatePicker
            placeholderText="Change Date"
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="MMMM d, yyyy"
              className="mt-1 p-2 block w-full rounded-md border border-gray-300"
              isClearable={true}
            />

            {/* <input
                type="date"
                placeholder={orderData.created_at}
                value={selectedDate}
                onChange={(e)=>setSelectedDate(e.target.value)}
                className="mt-1 p-2 block w-full rounded-md border border-gray-300"
                
              /> */}
            {/* )} */}
          </div>
          <div className="mt-3">
            {/* Save and Cancel buttons */}
            <button
              onClick={handleSave}
              className="px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-600"
            >
              Save
            </button>
            <button
              onClick={handleClose}
              className="ml-2 px-4 py-2 text-sm font-semibold text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
