import axios from 'axios';
import { adminBaseurl,userBaseurl } from './constants';


const createAxiosInstance = () => {
  const baseURL = process.env.REACT_APP_ADMIN_BASEURL
  const instance = axios.create({
    baseURL,
    withCredentials: true,
  });

  // Add a response interceptor
  // instance.interceptors.response.use(
  //   response => response,  // Pass through successful responses unchanged
  //   error => {
  //     if (error.response && error.response.status === 403) {
  //       window.location.reload();
  //     }
  //     return Promise.reject(error);
  //   }
  // );

  return instance;
};



const api = createAxiosInstance(adminBaseurl);


export default api ;