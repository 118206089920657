import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";

import { useEffect, useState } from "react";
import api from "../axios";
import { useContext } from "react";

import Header from "../components/NewComponents/Header";

import { BriefcaseIcon } from "@heroicons/react/20/solid";

import AddPaymentModal from "../components/PaymentsPage/AddPaymentModal";
import PaymentsTable from "../components/PaymentsPage/PaymetsTable";
import { AuthContext } from "../AuthContext";

const Buttons = [
  //   <HeaderButton
  //     name="Add Invoice"
  //     icon={<AddCircleOutline />}
  //     // onClick={}
  //   />

  <AddPaymentModal  />,
];

const SubDetails = [
  {
    title: "All Payments",
    icon: (
      <BriefcaseIcon
        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
    ),
  },
];

const Payments = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //   const { orderId } = useParams();

  // const { rowData, setRowData, getRows } = useContext(AuthContext);



  return (
    <Box sx={{ marginInline: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/* <Header title="Order Details" subtitle="" /> */}
        <Header title="Payments" 
        buttons={
          [<AddPaymentModal />]
          }
        subDetails={SubDetails} />
      </Box>

      <Box>
        <PaymentsTable fetch_type='all' />
      </Box>
    </Box>
  );
};

export default Payments;
