import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useMediaQuery from "@mui/material/useMediaQuery";
import CompanyDetailModal from "../Modals/CompanyDetailsModal";
import api from "../../axios";
import { toast } from "react-toastify";
import {
  MoreVertOutlined,
  Payments,
  LocalShipping,
  Settings,
  Tune,
} from "@mui/icons-material";
import AddLocationModal from "../Modals/LocationModal";
import OrdersModal from "../Modals/OrdersModal";
import TransactionsModal from "../Modals/TransactionsModal";
import DieselPriceModal from '../Modals/DieselPrice'
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";

export default function SubContractTable(props) {
  
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [contracts, setContracts] = React.useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const handleDelete = (id) => {
  //   api
  //     .delete(`company/${selectedCompany.id}`)
  //     .then((response) => {
  //       handleClose();
  //       props.updateTable();
  //       toast.success("Deleted Successfully");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error("An error occurred.Please Try again", error);
  //     });
  // };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  const fetchContracts = () => {
    api.get('sub-contract').then((response)=>{
      setContracts(response.data)
      console.log('contracttttttttttts',response.data);
    })
  }

  React.useEffect(() => {
    fetchContracts()
  }, [])
  

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#383a59", color: "white" }}>
                Trade Name
              </TableCell>
            
              <TableCell sx={{ backgroundColor: "#383a59", color: "white" }}>
                Company
              </TableCell>

              <TableCell sx={{ backgroundColor: "#383a59", color: "white" }}>
                Total Outstanding
              </TableCell>
            

              
             
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: colors.card2["bg"] }}>
            {contracts
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  <TableCell sx={{ fontSize: "0.9rem" }} align="left">
                    {row.trade_name}
                  </TableCell>
                  <TableCell sx={{ fontSize: "0.9rem" }} align="left">
                    {row.company_name}
                  </TableCell>
                  <TableCell sx={{ fontSize: "0.9rem" }} align="left">
                    {row.total_outstanding}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ backgroundColor: colors.blueAccent[900] }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
