import { Fragment } from "react";
import {
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  CurrencyDollarIcon,
  LinkIcon,
  MapPinIcon,
  PencilIcon,
  FunnelIcon,
  CurrencyRupeeIcon,
  XMarkIcon,
  PuzzlePieceIcon
} from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import { Button, List, ListItem, ListItemIcon, ListItemText, Popover } from "@mui/material";
import { Cancel, Delete, DeleteOutline, Done, Info, InfoOutlined, LocalShipping } from "@mui/icons-material";
import { useState } from "react";
import api from "../../axios";
import CompleteDeliveryModal from "./CompleteDeliveryModal";
import ApproveOrderModal from "./ApproveModal";
// import { confirm}
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import EditOrderModal from "./EditOrderModal";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



export default function OrderDetailsBox({orderData}) {
    const [selectedStatus, setSelectedStatus] = useState("");
    const [popoverAnchor, setPopoverAnchor] = useState(null);
    const [openPopover, setOpenPopover] = useState(false);
    const [statusTrigger,setStatusTrigger] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState();


  const [menuOpen, setMenuOpen] = useState(false);


      const handleOpenPopover = (event, id) => {
        console.log(id);
        setSelectedOrder(id);
        setPopoverAnchor(event.currentTarget);
        setOpenPopover(true);
      };

      const handleClosePopover = () => {
        setOpenPopover(false);
      };

      const handleStatusChange = (status) => {
        setSelectedStatus(status);
        setStatusTrigger(true)
        setMenuOpen(false);
        console.log(status);


      };


    //   const handleCloseDelivery = () => {
    //     setOpenDelivery(false);
    //   };

      const handleApplyStatus = () => {
        // Apply the selected status
        // You can add your logic here
        if (selectedStatus === "Delivered") {
        //   setOpenDelivery(true);
        } else {
          api
            .post(`change-orderstatus/${selectedOrder}`, {
              order_status: selectedStatus,
            })
            .then((response) => {
            //   fetchOrders();
            });
        }

        // Close the popover
        setStatusTrigger(false)
        handleClosePopover();
      };

    console.log('====================================');
    console.log(orderData);
    console.log('====================================');

    const handleStatusClick = (status) => {
        handleClosePopover()
      confirmAlert({
        title: "Confirm",
        message: `Are you sure you want to set the status to ${status}?`,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              setSelectedStatus(status);
              api
                .put(`change-orderstatus/${selectedOrder}`, {
                  order_status: status,
                })
                .then((response) => {
                  //   fetchOrders();
                  
                }).catch((error)=>{

                }).finally(()=>{
                    window.location.reload()
                });
            //   handleClose();
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    };


    function formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    }

    const navigate = useNavigate()

    const handleRemoveOrder = () => {
      confirmAlert({
        title: "Confirm",
        message: `Are you sure you want to remove this ? This action cannot be undone..!! `,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              // setSelectedStatus(status);
              api
                .delete(`remove-order/${orderData.id}`, {
                  // order_status: status,
                })
                .then((response) => {
                  toast.success("Order Has Been Deleted");
                  navigate('/orders')
                  window.location.reload();
                })
                .catch((error) => {
                  toast.error(error.response.data);
                });
              //   handleClose();
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    };

  return (
    <div className="w-full lg:flex lg:items-center lg:justify-between pb-5">
      <div className="min-w-0 flex-1">
        <div className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Order {orderData.order_id}&nbsp;&nbsp;
          <span className="text-sm sm:text-md text-yellow-600">
            {orderData.order_status}
          </span>
          {orderData.order_status === "Completed" ? (
            <span className="text-sm ml-2 sm:text-md text-indigo-600">
              {orderData.is_billable ? "Billable" : "Non-Billable"}
            </span>
          ) : (
            ""
          )}
        </div>

        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <BriefcaseIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {orderData?.company_name}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <MapPinIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {orderData?.location?.location}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <FunnelIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {orderData.requested_quantity} Ltrs
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {/* January 9, 2020 */}
            {formatDate(orderData.created_at)}
          </div>

          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CurrencyRupeeIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {orderData.diesel_price - orderData.discount_price}/Ltr
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:ml-4 lg:mt-0">
        <button
          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 mr-2"
          variant="outlined"
          size="small"
          onClick={(e) => handleOpenPopover(e, orderData.id)}
        >
          Change Status
        </button>
        <Popover
          // style={{ boxShadow: "none" }}
          PaperProps={{
            style: { boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" },
          }}
          open={openPopover}
          anchorEl={popoverAnchor}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className="w-[170px]" sx={{ p: 1 }}>
            <ApproveOrderModal
              req_qty={orderData.requested_quantity}
              orderId={orderData.id}
            />

            <button
              //   onClick={handleOpen}
              type="button"
              className="mb-1 inline-flex items-center w-full rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <XMarkIcon className="-ml-1 mr-2 h-6 w-6" aria-hidden="true" />
              Cancel
            </button>

            <button
              type="button"
              className="mb-1 inline-flex items-center w-full rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => handleStatusClick("Next Delivery")}
            >
              <InfoOutlined className="-ml-1 mr-2 h-6 w-6" aria-hidden="true" />
              Next Delivery
            </button>

            {/* Partial Button */}
            <button
              type="button"
              className="mb-1 inline-flex items-center w-full rounded-md bg-yellow-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => handleStatusClick("Partial")}
            >
              <PuzzlePieceIcon
                className="-ml-1 mr-2 h-6 w-6"
                aria-hidden="true"
              />
              Partial
            </button>
          </div>
        </Popover>

        {/* <Menu
          as="div"
          className="relative ml-3 "
          onClose={() => setMenuOpen(false)}
          open={menuOpen}
        >
          Status :{orderData.order_status}
          <Menu.Button
            onClick={() => setMenuOpen(!menuOpen)}
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          >
            Change
            <ChevronDownIcon
              className="-mr-1 ml-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <button
                    // href="#"
                    onClick={() => handleStatusChange("Approved")}
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700"
                    )}
                  >
                    Approved
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700"
                    )}
                  >
                    View
                  </a>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleApplyStatus}
                    href="#"
                    className={classNames(
                      //   active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700 bg-blue-600"
                    )}
                  >
                    Apply
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
          {statusTrigger === true ? (
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <CheckIcon
                className="-ml-0.5 mr-1.5 h-5 w-5"
                aria-hidden="true"
              />
              Apply Status
            </button>
          ) : (
            ""
          )}
        </Menu> */}

        <span className="hidden sm:block">
          {/* <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <PencilIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            /> */}
          {/* Edit */}
          {/* </button> */}

         <EditOrderModal orderData={orderData}/>

          <button
            onClick={() => handleRemoveOrder()}
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <DeleteOutline
              className="-ml-0.5  h-2 w-5 text-red-400"
              aria-hidden="true"
            />
            {/* Edit */}
          </button>
        </span>

        {/* <Delete /> */}

        {orderData.order_status === "Partial" ||
        orderData.order_status === "Approved" ? (
          <span className="ml-3 hidden sm:block">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <LinkIcon
                className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              Add Delivery
            </button>
          </span>
        ) : (
          ""
        )}

        {orderData.order_status === "Partial" ? (
          // <span className="sm:ml-3">
          //     <button
          //       type="button"
          //       className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          //     >
          //       <CheckIcon
          //         className="-ml-0.5 mr-1.5 h-5 w-5"
          //         aria-hidden="true"
          //       />
          //       Complete Order
          //     </button>
          //   </span>
          <CompleteDeliveryModal
            orderId={orderData.id}
            deliveredQty={orderData.delivered_quantity}
            approvedQty={orderData.approved_quantity}
          />
        ) : (
          "'"
        )}
        {/* Dropdown */}
        <Menu as="div" className="relative ml-3 sm:hidden">
          <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
            More
            <ChevronDownIcon
              className="-mr-1 ml-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700"
                    )}
                  >
                    Edit
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700"
                    )}
                  >
                    View
                  </a>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
